import { OptionType } from 'components/molecules/Pulldown';

const SLUG_PARAMS = {
  HOME: '/',
  WISHLIST: '/wishlist',
  MANUFACTURES: '/manufacturers',
  PRODUCTS_DETAIL: '/product-detail',
  CATEGORY_DETAIL: '/category',
  SUB_CATEGORY: '/sub-category',
  CART: '/cart',
  ORDER_COMPLETED: '/order-completed',
  SEARCH_RESULT: '/search-result',
  CHANGE_PASSWORD: '/change-password',
  CUSTOMER_INFO: '/customer-info',
  CALLBACK_ZALO: '/callback/zalo',
  LINK_SOCIAL_ZALO: '/link-social/zalo',
  POLICY_RULES: '/policy-rules',
  POLICY_SECURE: '/policy-secure',
  POLICY_DELIVERY: '/policy-delivery',
  POLICY_RETURN: '/policy-return',
  POLICY_GUARANTEE: '/policy-guarantee',
  POLICY_BCOIN: '/policy-bcoin',
  MOLINARI2024: '/molinari2024',
  COMPONENTS: '/test', // TODO: Test purpose only
};

export const PERCENT5 = 0.05;

export const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const AUTHENTICATION_STEP = {
  USERNAME: 0,
  PASSWORD: 1,
};

export const PASSWORD_STEP = {
  PASSWORD: 0,
  OTP: 1,
  FORGOT_PASSWORD: 2,
  VERIFY_OTP: 3,
  COMPLETE: 4,
  SET_PASSWORD: 5,
};

export const dayOptions: OptionType[] = [
  {
    label: '01',
    value: '01',
  },
  {
    label: '02',
    value: '02',
  },
  {
    label: '03',
    value: '03',
  },
  {
    label: '04',
    value: '04',
  },
  {
    label: '05',
    value: '05',
  },
  {
    label: '06',
    value: '06',
  },
  {
    label: '07',
    value: '07',
  },
  {
    label: '08',
    value: '08',
  },
  {
    label: '09',
    value: '09',
  },
  {
    label: '10',
    value: '10',
  },
  {
    label: '11',
    value: '11',
  },
  {
    label: '12',
    value: '12',
  },
  {
    label: '13',
    value: '13',
  },
  {
    label: '14',
    value: '14',
  },
  {
    label: '15',
    value: '15',
  },
  {
    label: '16',
    value: '16',
  },
  {
    label: '17',
    value: '17',
  },
  {
    label: '18',
    value: '18',
  },
  {
    label: '19',
    value: '19',
  },
  {
    label: '20',
    value: '20',
  },
  {
    label: '21',
    value: '21',
  },
  {
    label: '22',
    value: '22',
  },
  {
    label: '23',
    value: '23',
  },
  {
    label: '24',
    value: '24',
  },
  {
    label: '25',
    value: '25',
  },
  {
    label: '26',
    value: '26',
  },
  {
    label: '27',
    value: '27',
  },
  {
    label: '28',
    value: '28',
  },
  {
    label: '29',
    value: '29',
  },
  {
    label: '30',
    value: '30',
  },
  {
    label: '31',
    value: '31',
  },
];

export const monthOptions: OptionType[] = [
  {
    label: 'Tháng 1',
    value: '01',
  },
  {
    label: 'Tháng 2',
    value: '02',
  },
  {
    label: 'Tháng 3',
    value: '03',
  },
  {
    label: 'Tháng 4',
    value: '04',
  },
  {
    label: 'Tháng 5',
    value: '05',
  },
  {
    label: 'Tháng 6',
    value: '06',
  },
  {
    label: 'Tháng 7',
    value: '07',
  },
  {
    label: 'Tháng 8',
    value: '08',
  },
  {
    label: 'Tháng 9',
    value: '09',
  },
  {
    label: 'Tháng 10',
    value: '10',
  },
  {
    label: 'Tháng 11',
    value: '11',
  },
  {
    label: 'Tháng 12',
    value: '12',
  },
];

export const yearOptions: OptionType[] = [];

export const CART_TYPE = {
  WISHLIST: 2,
  CART: 1,
};

for (let year = 1970; year <= new Date().getFullYear(); year += 1) {
  yearOptions.push({
    label: `${year}`,
    value: `${year}`,
  });
}

export const policyUrls = [
  { title: 'Chính sách và quy định chung', link: SLUG_PARAMS.POLICY_RULES },
  { title: 'Chính sách bảo mật thông tin', link: SLUG_PARAMS.POLICY_SECURE },
  {
    title: 'Chính sách giao nhận và thanh toán',
    link: SLUG_PARAMS.POLICY_DELIVERY,
  },
  { title: 'Chính sách đổi trả sản phẩm', link: SLUG_PARAMS.POLICY_RETURN },
  { title: 'Chính sách bảo hành', link: SLUG_PARAMS.POLICY_GUARANTEE },
  {
    title: 'Chính sách tích điểm, sử dụng Bcoin',
    link: SLUG_PARAMS.POLICY_BCOIN,
  },
];

export default SLUG_PARAMS;
